<template>
  <b-card
    :border-variant="borderVariant"
    class="card-transaction"
    no-body
  >
    <b-card-body
      class="transaction-item d-flex align-items-center"
    >
      <b-media no-body>
        <b-media-aside
          vertical-align="center"
        >
          <b-avatar
            rounded
            size="42"
            :style="`background-color: ${indicatorColor};`"
          >
            <feather-icon
              size="18"
              :icon="icon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="transaction-title">
            {{ title }}
            <feather-icon
              v-b-tooltip.hover.v-secondary.topright="tooltipDescription"
              class="text-secondary"
              size="12"
              icon="AlertCircleIcon"
            />
          </h6>
          <small>{{ subtitle }}</small>
        </b-media-body>
      </b-media>
      <div
        v-b-tooltip.hover.v-primary
        class="font-weight-bolder mb-0"
        :title="__numberWithCommas(sum, true)"
      >
        {{ businessSettings.company_currency }} {{ kFormatter(sum) }}
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  VBTooltip,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  name: 'OverviewCard',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BAvatar,
    BCard,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,

  },
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    subtitle: {
      type: String,
      default: 'Subtitle',
    },
    sum: {
      type: Number,
      default: 0,
    },
    tooltipDescription: {
      type: String,
      default: 'tooltip description',
    },
    borderVariant: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    indicatorColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      businessSettings: null,
    }
  },
  created() {
    this.businessSettings = JSON.parse(localStorage.getItem('businessSettings'))
  },
  methods: {
    kFormatter,
  },
}
</script>
